import React from "react"
import Image from "gatsby-image"
import PerfectScrollbar from "react-perfect-scrollbar"
import Button from "./buttons"

import "./businessCard.css"

const BusinessCard = ({ image, shadow, title, subtitle, shortDetails, details, button }) => {
  return (
    <div className='business-card'>
      <Image fluid={image} style={{ position: "absolute" }} className='business-card-image'/>
      <div className='business-card-info'>
        <Image
          fluid={shadow}
          style={{ position: "absolute", width: "100%", height: "20px", bottom: "-20px", left: "0", zIndex: "-1" }}
          loading="eager"
          imgStyle={{ objectFit: "100% 100%" }}
        />
        <div className='business-card-info-content'>
          <div>
            <div className='business-card-info-header'>
              { subtitle ? (
                <React.Fragment>
                  <div>{title}</div>
                  <div>{subtitle}</div>
                </React.Fragment>
                ) : (
                title
              )}
            </div>
            {shortDetails && <div className='business-card-info-subheader'>{shortDetails}</div>}
          </div>
          <div className='business-card-info-details'>
            <PerfectScrollbar dangerouslySetInnerHTML={{__html: details}} />
          </div>
        </div>
        <Button as='a' type='black' name={button.text} aria-label={button.text} className='business-card-button' href={button.url}>{button.text}</Button>
      </div>
    </div>
  )
}

export default BusinessCard
